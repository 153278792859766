import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { fr } from 'date-fns/locale';
import i18next from 'i18next';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zTranslation from 'zod-i18n-map/locales/fr/zod.json';

import { App } from './App.tsx';
import { queryClient } from './config/query-client.ts';
import theme from './config/theme.ts';
import { useAuthStore } from './features/authentification/stores/auth.store.ts';
import './index.css';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.0, // Capture 100% of the transactions
    // Session Replay
    //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

i18next.init({
  lng: 'fr',
  resources: {
    fr: { zod: zTranslation },
  },
});
z.setErrorMap(zodI18nMap);

useAuthStore.getState().init();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <Suspense>
            <App />
          </Suspense>
        </LocalizationProvider>
        <ToastContainer />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
