import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import Desktop from '@/assets/images/Desktop.svg';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      darkLight: string;
      grayDark: string;
      grayLight: string;
      orangeLight: string;
      darkBlue: string;
      redDark: string;
      pinkLight: string;
      orangeExtraLight: string;
      green: string;
      greenLight: string;
      gray500: string;
    };
  }

  interface PaletteOptions {
    custom?: {
      darkLight: string;
      grayDark: string;
      grayLight: string;
      orangeLight: string;
      darkBlue: string;
      redDark: string;
      pinkLight: string;
      orangeExtraLight: string;
      green: string;
      greenLight: string;
      gray500: string;
    };
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',

    primary: {
      main: '#f1a529',
      contrastText: '#fff',
    },
    secondary: {
      main: '#061360',
      contrastText: '#fff',
    },
    custom: {
      darkLight: '#474343',
      grayLight: '#D6D8D7',
      orangeLight: '#FEF6EA',
      redDark: '#D63C3C',
      pinkLight: '#F5CECE',
      grayDark: '#474343',
      orangeExtraLight: '#FCEDD4',
      green: '#60CC0B',
      greenLight: '#DFF5CE',
      darkBlue: '#061360',
      gray500: '#C0C0C0',
    },
  },

  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#60a5fa',
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${Desktop})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
