import { Box, CircularProgress } from '@mui/material';
import React, { lazy } from 'react';

import { useAuthStore } from './features/authentification/stores/auth.store';

const RouterProvider = lazy(() => import('./RouterProvider'));

export const App: React.FC = () => {
  const isInitialized = useAuthStore((state) => state.isInitialized);

  return (
    <>
      {isInitialized ? (
        <RouterProvider />
      ) : (
        <Box
          sx={{
            width: '100%',
            mt: '45vh',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
